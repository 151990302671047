/* Component Dependencies */
var pageStaticContentCss = require('templates/pageStaticContent-css.hbs');
var pageStaticContentJs = require('templates/pageStaticContent-js.hbs');
var AriesComponent = require('libs/aries-component');

AriesComponent.create({
  type: 'pageStaticContent',
  template: {
    'pageStaticContent-css': pageStaticContentCss,
    'pageStaticContent-js': pageStaticContentJs
  }
});
